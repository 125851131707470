import React from "react";
import CustomizedSnackbars from "./components/Snackbar";
import All from "./components/All";
import Loading from "./components/Loading";
import controls from "./components/controls";
import { ThemeProvider } from '@mui/material/styles';
import theme from "./components/FrecallTheme";


function App() {
  
  console.log("APPPP");

  let urlData = {};

  window.location.search.replaceAll("?","").split("&").forEach(element => {
    const elementList = element.split("=");
    urlData[elementList[0]] = elementList[1];
  })

  controls.channelName = urlData.agentName;
  controls.roomId = urlData.roomId; //sets the frecall Room ID.
  
  // console.log(controls.channelName)
  // console.log(controls.roomId)

  return (
    <ThemeProvider theme={theme}>
      <Loading />
      <CustomizedSnackbars/>
      <All/>
  </ThemeProvider>
  )
}

export default App;
