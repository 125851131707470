import React, { useRef } from "react";
import sound from "./sound.mp3";
// import useAudioStore from "../store/useAudioStore";

const AudioPlayer = () => {
  const audioRef = useRef(null);
  // const { isPlaying } = useAudioStore();

  return (
    <div>
      <video ref={audioRef} controls webkit-playsinline="true" playsInline={true} 
      // muted={isPlaying} 
      id="myPlayer">
        <source src={sound} type="audio/mp3"  />
      </video>
    
    </div>
  );
};

export default AudioPlayer;
