import React from 'react'
import AudioPlayer from './AudioPlayer'
import CallerQueue from './CallerQueue'
import AudioControls from './AudioControls'
import Auth from './Auth/Auth'
import { Stack } from "@mui/material";
import { getUsersOnCall, setAgoraToken } from './firebase'
import { useState } from 'react'
import controls, { endCall, setOnAuthStateChanged } from './controls'
import CallerTile from './CallerTile'
import "./call_recording"

let startedListening = false;
let firstTimeLoading = true;

const All = () => {
    let [view, setView] = useState("")

    if(firstTimeLoading){
        firstTimeLoading = false;
        controls.setView = setView;
        setOnAuthStateChanged();
    }

    let [usersOnCall, setUsersOnCall] = useState([])


    function handleUserChange(doc){
        const usersOnQueue = doc.usersOnQueue;
        //   setUsersOnCall((userOnCall)=>{

            if(usersOnCall === usersOnQueue){
            //   console.log("SAME");
              return
            }
    
            // console.log(controls.callerIdOnCall);
            if(controls.callAnswered && (controls.callerIdOnCall||"").length > 2 && !usersOnQueue.includes(controls.callerIdOnCall)){
              // console.log(controls.callerIdOnCall);
              // console.log(`Ending call for userId ${controls.callerIdOnCall}`);
              endCall(controls.setMicOn, controls.setTime, controls.callerIdOnCall)
            }
    
            // console.log("onCall");
            // console.log(onCall);

            if(usersOnQueue.length > 0 && !controls.callAnswered){
              try {
                document.getElementById("myPlayer").volume = controls.volume/100;
                document.getElementById("myPlayer").play();
              } catch (error) {
                // console.log(error);
              }
            }
    
            const newUserComponentList = usersOnQueue.map((id)=> <CallerTile answeredBy={(doc[id]) || ""} key={id} id={id}/>)
        //   console.log(newUserComponentList);
          setUsersOnCall(newUserComponentList)
      }
    
      if((view === "call") && !startedListening){
        startedListening = true;
        // console.log("APP")
        setAgoraToken().then((allowed)=>{
          if(allowed)
             getUsersOnCall(handleUserChange)
        });
      }

  return (
    view === "call"? (
        <>
          <div hidden><AudioPlayer/></div>
          <Stack direction="row">
            <AudioControls/> 
            <CallerQueue usersOnCall={usersOnCall}/>
          </Stack>
        </>
      ): <Auth setView={setView} view={view}/> 
  )
}

export default All
