import { initializeApp } from "firebase/app";
import { getFirestore, collection, onSnapshot, doc } from 'firebase/firestore'
import { getFunctions, httpsCallable } from "firebase/functions";
import controls from "./controls"
import { getAuth } from "firebase/auth";
import { showSnackbar } from "./Snackbar";

const firebaseConfig = {
    apiKey: "AIzaSyDcREtwTpF5RUE7-3Top67i5dXY6ltB-eQ",
    authDomain: "frecall-a0b07.firebaseapp.com",
    projectId: "frecall-a0b07",
    storageBucket: "frecall-a0b07.appspot.com",
    messagingSenderId: "446467769626",
    appId: "1:446467769626:web:9836e247e29e8cf83c58a3",
    measurementId: "G-VR8RSGYYF5"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);


async function getUsersOnCall(handleUserChange) {

    const referenceId = controls.roomId;
    // console.log("referenceId:", referenceId);

    const usersOnCallCollection = collection(db, "usersOnCall");
    const usersOnCallReference = doc(usersOnCallCollection, `${referenceId}`);
    // const usersOnCallSnap = await firestore.getDoc(usersOnCallReference);

    onSnapshot(usersOnCallReference, function(doc) {
      // console.log(doc.data());
        handleUserChange(doc.data());
    })

    // console.log(usersOnCallSnap.data().onCall)
  }

async function setAgoraToken(){
  const functions = getFunctions();

  if((controls.appId || "").length < 2){
    const allowed = await getRoomDetails();
    // console.log({allowed});
    if (!allowed) return false;
  }

  // console.log("TTTTTTTTTT");

  const generateAgoraToken = httpsCallable(functions, 'generateAgoraToken');
  try{
   await generateAgoraToken({agent: true, channelName: controls.channelName, appId: controls.appId}).then((result)=>{
    // console.log("RRRRRRR", result.data);
    controls.token = result.data;
  })
  return true;
  
  } catch(err){
    // console.log("EEEEEERRR",err)
  }

}

async function getRoomDetails() {
    const functions = getFunctions();

    const getRoomDetails = httpsCallable(functions, 'getRoomDetails');

    let allowed = false;

    try{
      await getRoomDetails({"roomId": controls.roomId,"agentName": controls.channelName, "email": auth.currentUser.email}).then((result)=>{
      // console.log("RRRRRRR", auth.currentUser.uid,", ", controls.channelName);

      if(result.data.allowed){
        
      controls.appId = result.data.callAppId;
      controls.rooms = result.data.rooms;
      allowed = true;
      } else {
        showSnackbar("You are not yet authorised as an agent in this call room, please contact your manager.", "error")
      }
    })

    return allowed;

    } catch(err){
      // console.log("EEEEEERRR",err)
      return null;
    }
}

export default db;
export {getUsersOnCall, setAgoraToken, getRoomDetails, auth};
