import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#004d40', // Dark green color for primary elements
    },
    secondary: {
      main: '#000000', // Black color for secondary elements
    },
  },
  components: {
    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: '#004d40', // Dark green background color for buttons
    //       color: '#fff', // White text color for buttons
    //     },
    //   },
    // },
    // MuiIconButton: {
    //   styleOverrides: {
    //     root: {
    //       color: '#004d40', // Dark green color for icons
    //     },
    //   },
    // },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     root: {
    //       '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    //         borderColor: '#004d40', // Border color when the TextField is focused
    //       },
    //     },
    //   },
    // },
  },
});


export default theme;