import React from "react"
import { showSnackbar } from "./Snackbar";

import { Box, Button, ButtonGroup, Chip, IconButton, Paper, Popover, Stack, Typography } from "@mui/material";

import PersonIcon from '@mui/icons-material/Person';
import CallIcon from '@mui/icons-material/Call';
import MicOff from "@mui/icons-material/MicOff";
import CallEndIcon from '@mui/icons-material/CallEnd';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MicIcon from '@mui/icons-material/Mic';
import controls, { answerCall, endCall, queueBack } from "./controls";


function CallerTile(props){

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [time, setTime] = React.useState(controls.callDuration);
    const [micOn, setMicOn] = React.useState(controls.micOn);

    controls.setTime = setTime;
    controls.setMicOn = setMicOn;

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleMicToggle = ()=>{
        controls.micOn = !controls.micOn;
        setMicOn(controls.micOn);
        try{
            controls.microphoneTrack.setEnabled(controls.micOn)
        } catch(err){}
    }
  
    const open = Boolean(anchorEl);
    const id = open ? 'more-menu' : undefined;
  

    function copyCallerId(){

        if(props.id.toString().includes("null")){
            showSnackbar("Anonymous caller.","error");
        } else {
            navigator.clipboard.writeText(props.id);
            showSnackbar("Caller id copied to Clipboard!","success");
        }
    }

    function sendToAnotherRoom(room){
        // navigator.clipboard.writeText(props.id);
        queueBack(props.id, room.roomId, room.name, setTime, setMicOn)
    }

    // console.log(props.id);

    if(!controls.callAnswered && (props.answeredBy === controls.channelName)){
        controls.callAnswered = true;
        // console.log("GGGGGGGGGGGGGGGG");
        answerCall(props.id, setTime, true);
    }

    const callAnsweredBySomeOneElse = props.answeredBy.length>2 && (props.answeredBy !== controls.channelName)

    return (
        <Box position="relative" margin={0.5}>
            <Paper elevation={4}>
                <Stack alignItems="center">
                    <IconButton disabled = {callAnsweredBySomeOneElse} variant="contained" onClick={handleClick} aria-describedby={id} sx={{alignSelf:"flex-end"}}><MoreHorizIcon size="large"/></IconButton>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }} >
                            <ButtonGroup variant="contained" orientation="vertical" size="small" color="grey" aria-label="alignement button group">
                                <Button disabled={props.id.toString().includes("null")} onClick={copyCallerId}>Copy uid</Button>
                                <Button onClick={()=> sendToAnotherRoom({name: controls.channelName, roomId: controls.roomId})} color="warning">Queue back</Button>
                                {controls.rooms.filter((room)=> room.roomId !== controls.roomId).map((room) => {
                                    return <Button key={room.roomId} onClick={()=> sendToAnotherRoom(room)} color="warning">Send to {room.name} room</Button>;
                                })}
                            
                            </ButtonGroup>
                        </Popover>
                    <PersonIcon cursor="pointer" onClick={copyCallerId} sx={{fontSize:callAnsweredBySomeOneElse?"40px":"120px", marginTop:"10px", marginBottom:controls.callerIdOnCall === props.id?"0px":"40px"}}/>
                    {controls.callerIdOnCall === props.id && <Box height="40px"><Typography variant="h5">{`${("0" + Math.floor(time/3600)).slice(-2)}:${("0" + Math.floor(time/60)).slice(-2)}:${("0" + time%60).slice(-2)}`}</Typography></Box>}
                    {callAnsweredBySomeOneElse && <Chip sx={{py:"20px", px:"10px", mb:"40px", fontSize:"20px"}} color="success" icon={<CallIcon color="success"/>} label={props.answeredBy} size="large"/>}
                    <Stack 
                    direction="row" spacing={0.5}>
                        <Button disabled = {callAnsweredBySomeOneElse} onClick={()=>endCall(setMicOn, setTime, props.id)} variant="outlined" aria-label="End Call" color="error"><CallEndIcon/></Button>
                        <Button disabled={controls.callerIdOnCall.length < 2} onClick={handleMicToggle} size="small" variant="outlined" aria-label="Mic Toggle" color="primary">{(controls.callerIdOnCall === props.id && !micOn)?<MicOff/>:<MicIcon/>}</Button>
                        <Button onClick={()=>answerCall(props.id, setTime)} disabled={callAnsweredBySomeOneElse || controls.callerIdOnCall === props.id}  size="large" variant="outlined" aria-label="Answer Call" color="success"><CallIcon/></Button>
                    </Stack>
                </Stack>
            </Paper>
        </Box>
    );
}

export default CallerTile;

// export {setMicOn_, setTime_}

