import React from 'react'
import SignIn from './SignIn'
import SignUp from './SignUp'

const Auth = (props) => {

  return (
    (props.view === "signin"? 
        <SignIn setView={props.setView}/>:
        (props.view === "signup"?
            <SignUp setView={props.setView}/>:
            <div></div>))
  )
}

export default Auth
