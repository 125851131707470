import React from 'react'
import { Stack } from '@mui/material'

const CallerQueue = (props) => {
  return (
 <Stack
    style={{overflowX:"scroll"}} 
    direction="row">
    {props.usersOnCall}
  </Stack>
  )
}

export default CallerQueue
